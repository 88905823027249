

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


:root {
  --primary: black;
  --secondary: white;
  --dark-gray: rgb(78, 78, 78);
  --accent: crimson;
  --fadedAccent: rgb(239, 133, 154);
  --fadedPrimary: rgb(122, 119, 119);

}


html {
  height: 100%;
}

body {
  height: 100%;
  max-width: 80vw;
  margin: auto;
 
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--secondary);
  
  
}

@media (max-width: 300px) {
  body {
    max-width: 90vw;
    
  }
}
/********************* NAVBAR *******************/

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 75px;
  margin-top: 100px;
  /* flex-wrap: wrap; */
  align-items: center;
  margin-inline: 20px;
  
}

.nav-items {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding: 0;

}

.nav-item,
.nav-name  {
  text-decoration: none;
  padding-block: 10px;
  text-decoration: none;
  color: var(--accent);
  font-size: 24px;
  font-weight: 100;
}
.nav-name {
  color: var(--primary);
}


#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

h1 {
  font:inherit;
}
li {
  font: inherit;
  list-style: none;
}

.nav-item,
.nav-name {
 transition: color 500ms;

}


.nav-item:hover {
  color: var(--fadedAccent);
}
.nav-name:hover {
  color: var(--fadedPrimary);
}

.nav-active.nav-item {
  border-bottom: 1px solid var(--accent);
}

@media (max-width: 37.5rem) {
  .nav-container {
    margin-bottom: 25px;
    margin-top: 50px;
    
  }


.nav-items {
  gap: 8px;
}

 .nav-item,
 .nav-name {
    font-size: 18px;
    font-weight: 200;
  }
  .nav-name {
    margin-right: 10px;
  }
}

/********************* GALLERY *******************/

@import "~react-image-gallery/styles/css/image-gallery.css";



#gallery-container {
  background-color: rgb(236, 239, 239);
  padding: 5vw;
}

/********************* MAIN*****************/

#main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}


#intro-image,
#about-image {
  width: 100%;
  height: auto;
  padding: 3vw;
  background-color: var(--secondary);
}
#about-image {
  border: 1px solid var(--primary);
}

#intro-image {
  width: 50vw;
  height: auto;
  transition: contrast 1300ms;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}
#intro-image:hover {
  filter: contrast(1.1);
}

#intro-image-container {
  display: flex;
  justify-content: center;
}

#about-image-container {
  flex: 0.5;
}


@media (max-width: 37.5rem) {
  #intro-image {
    width: 80vw;
    height: auto;
    border-radius: 5px;
  }
}


/********************* ABOUT*****************/

.about-container {
  margin-left: auto;
  margin-right: auto;
}

.about-container * {
  padding-left: 10vw;
  font-size: 24px;
  font-weight: 100;
  font-style: normal;
  margin: 0;
  word-spacing: 1;

  
}

@media (max-width: 37.5rem) {
  .about-container *{
    font-size: 16px;
  }

}





/********************* CONTACT FORM *****************/

.cf {
 
  max-width: 300px;
  align-items: stretch;
  margin-inline: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

}

.form-field {
  flex-shrink: 1;
  background-color: var(--secondary);
  border: 1px solid lightgray;
  padding: 1em;
  border-radius: 8px;
  display: block;
  resize: none;
  font-family:inherit;
 }

 textarea.form-field {
  height: 200px;
}

button.form-field {
  color: var(--accent);
}

.form-field:hover {
  border: 1px solid rgb(156, 156, 156);
}
.form-field:focus {
  /* border: 1px solid var(--accent); */
  outline: none;
}


/* **************** FOOTER ************************** */


footer  {
  margin-top:15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer * {
  text-decoration: none;
  color: var(--accent);
  size:16px;
  margin-left: 5px;
}

.printer{
  text-decoration: none;
  color: var(--accent);
  font-size: 14px;
  display: flex;
}
.footer-right {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  justify-content: right;
  
}

.footer-right * {
  color: var(--primary);
}

@media  (max-width:37.5rem){
  footer {
    font-size: 14px;
  }
}

.masonry-img {

  filter: grayscale(100%) contrast(120%);
  transition: filter 500ms;
  border-radius: 5px;
  outline: 1px solid var(--accent);
}

.masonry-img:hover {
  filter: drop-shadow(0px 0px 4px gray);
  filter: none;
  cursor:pointer;
  outline: 1px solid var(--accent);
}



@media print {
  .masonry-img {
    filter: none;
  }
  footer {
    visibility: hidden;
  }
}